import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Grid,
  ButtonGroup,
  Button,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ContractService from "../ui/ContractService";
import ReportModal from "./ReportModal";
import LinearProgress from "@material-ui/core/LinearProgress";
import OutlinedDiv from "../ui/OutlinedDiv";
import { green, blue, red, amber } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    info: {
      light: blue[300],
      main: blue[500],
      dark: blue[700],
      darker: blue[900],
    },
    success: {
      light: green[300],
      main: green[500],
      dark: green[700],
      darker: green[900],
    },
    error: {
      light: red[300],
      main: red[500],
      dark: red[700],
      darker: red[900],
    },
  },
});
import {
  formatDateUS,
  validateService,
  clearService,
  onlyUnique,
  isInteger,
  formatDateAndTime,
} from "../../helpers";
import Creatable from "react-select/creatable";
import makeAnimated from "react-select/animated";
import alertify from "alertifyjs";
import axios from "axios";
import MyAlert from "../MyAlert";

const textColor = "#050505";
const token = document.querySelector('[name="csrf-token"]').content;
axios.defaults.headers.common["X-CSRF-Token"] = token;
alertify.set("notifier", "position", "top-right");
alertify.set("notifier", "delay", 5);
const animatedComponents = makeAnimated();

const RFOReport = ({
  service,
  closeBtnClick,
  templates,
  contractServices,
  flightType,
  adminId,
  isOnline,
  updateService,
  names,
  locale,
}) => {
  const flightInbound = flightType === "arrival";
  const createData = (th1, td1) => {
    return { th1, td1 };
  };

  const localRfoReport = JSON.parse(
    localStorage.getItem(`rfo_report_${service.id}`)
  );

  const localCrewMembers = JSON.parse(
    localStorage.getItem(`crew_members_${service.id}`)
  );

  const rows = flightInbound
    ? [
        createData(locale("flight_number").toUpperCase(), service.flight_in),
        createData("STA", service.flight_sta),
        createData("ETA", service.flight_eta),
        createData(locale("gate").toUpperCase(), service.flight_gate),
      ]
    : [
        createData(locale("flight_number").toUpperCase(), service.flight_out),
        createData("STD", service.flight_std),
        createData("ETD", service.flight_etd),
        createData(locale("gate").toUpperCase(), service.flight_dgate),
      ];

  const [removeServices, setRemoveServices] = useState([]);
  const [newReport, setNewReport] = useState(true);
  const [rampReport, setRampReport] = useState({});
  const [servicesByContract, setServicesByContract] = useState(
    localRfoReport || []
  );
  const [additionalServices, setAdditionalServices] = useState([]);
  const [servicesCount, setServicesCount] = useState({});
  const [errors, setErrors] = useState({});
  const [selectedService, setSelectedService] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [extraStepFinished, setExtraStepFinished] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [numEmployees, setNumEmployees] = useState(0);
  const [crewMembers, setCrewMembers] = useState(
    localCrewMembers || {
      loader: [],
      aft_loader: [],
      tug_drivers: [],
      bulck1: [],
      bulck2: [],
    }
  );

  useEffect(() => {
    let arr = [];
    Object.keys(crewMembers).forEach((key) => arr.push(crewMembers[key]));
    arr = arr.flat().filter(onlyUnique);
    setNumEmployees(arr.length);
  }, [crewMembers, setCrewMembers]);

  const handleChange = (name, value) => {
    setCrewMembers({
      ...crewMembers,
      [name]: value,
    });
  };

  const contract_services =
    contractServices.length == 0
      ? ["1", "2", "3", "4", "5", "6", "7", "8"]
      : contractServices;
  const filteredTemplates = templates
    .filter((t) => t[flightType] === true)
    .sort((a, b) => {
      return a.priority <= b.priority ? -1 : 1;
    });

  const delay = flightInbound
    ? Math.round(service.arrival_delay / 60)
    : Math.round(service.departure_delay / 60);
  let statusColor = green[600];
  if (delay > 15) {
    statusColor = red[600];
  } else if (delay > 0 && delay <= 15) {
    statusColor = amber[600];
  }

  useEffect(() => {
    const arr1 = [];
    const arr2 = [];
    for (let i = 0; i < filteredTemplates.length; i++) {
      contract_services.includes(filteredTemplates[i].id.toString())
        ? arr1.push(
            clearService(
              filteredTemplates[i],
              service.flight_date,
              flightInbound ? service.flight_sta : service.flight_std
            )
          )
        : arr2.push(
            clearService(
              filteredTemplates[i],
              service.flight_date,
              flightInbound ? service.flight_sta : service.flight_std
            )
          );
    }
    if (servicesByContract.length === 0) {
      setServicesByContract(arr1);
    }
    setAdditionalServices(arr2);
    setServicesCount(
      arr2.reduce((obj, service) => {
        obj[service.code] = 1;
        return obj;
      }, {})
    );
    setLoaded(true);
    const timeOut = setTimeout(() => {
      setShowForm(true);
    }, 100 * contract_services.length);
    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  useEffect(() => {
    if (loaded && localRfoReport === null) {
      if (!flightInbound) {
        axios
          .get(`/admin/services/${service.id}/shared_services`)
          .then((response) => {
            if (response.status === 200) {
              response.data.shared_services.forEach((el) => {
                el.start_time = new Date(el.start_time);
                el.end_time = new Date(el.end_time);
                setServicesByContract((prevState) => {
                  let newState = [...prevState, el];
                  return newState;
                });
                setServicesCount((prevState) => {
                  const newState = { ...prevState };
                  newState[el.code] += 1;
                  return newState;
                });
              });
              // TODO set rfo report
              const { rfo_report } = response.data;
              const { report_services } = response.data;
              if (rfo_report != null && report_services.length > 0) {
                setNewReport(false);
                setRampReport(rfo_report);
                setNumEmployees(rfo_report.emp_num);
                setServicesByContract(
                  report_services.map((s) => {
                    s.start_time = new Date(s.start_time);
                    s.end_time = new Date(s.end_time);
                    return s;
                  })
                );
                setServicesCount(
                  report_services.reduce((obj, service) => {
                    obj[service.code] = 1;
                    return obj;
                  }, {})
                );
                Object.keys(crewMembers).forEach((key) => {
                  if (rfo_report[key] != "") {
                    setCrewMembers((prevState) => {
                      return {
                        ...prevState,
                        [key]: rfo_report[key].split(", ").map((name) => {
                          return { label: name, value: name };
                        }),
                      };
                    });
                  }
                });
              }
            }
          });
      }
      if (service.additional_services?.length > 0) {
        addServices(
          service.additional_services
            .filter((i) => i != "")
            .map((i) => parseInt(i))
        );
      }
    } else if (loaded && localRfoReport !== null) {
      setServicesByContract((prevState) => {
        const newState = [...prevState].map((el) => {
          el.start_time = new Date(el.start_time);
          el.end_time = new Date(el.end_time);
          setServicesCount((prevState) => {
            const newState = { ...prevState };
            newState[el.code] += 1;
            return newState;
          });
          return el;
        });
        return newState;
      });
    }
  }, [loaded, setLoaded]);

  const closeBtnHandler = () => {
    closeBtnClick(false);
  };

  const saveBtnHandler = () => {
    localStorage.setItem(
      `rfo_report_${service.id}`,
      JSON.stringify(servicesByContract)
    );
    localStorage.setItem(
      `crew_members_${service.id}`,
      JSON.stringify(crewMembers)
    );
    alertify.message("Your draft was saved.", 2);
  };

  const deleteBtnHandler = () => {
    localStorage.removeItem(
      `rfo_report_${service.id}`,
      JSON.stringify(servicesByContract)
    );
    localStorage.removeItem(
      `crew_members_${service.id}`,
      JSON.stringify(crewMembers)
    );
    alertify.error("Your draft was deleted.", 2);
  };

  const addServices = (ids) => {
    ids.forEach((id) => {
      let event = { target: { value: id } };
      addServiceHandler(event);
    });
    if (ids.length > 0) {
      setExtraStepFinished(true);
      setIsDisabled(false);
    }
  };

  const addServiceHandler = (event) => {
    const id = event.target.value;
    const newId = Math.random().toString(16).slice(2);
    let selected = filteredTemplates.filter((s) => s.id === id)[0];
    setSelectedService(id);
    if (selected) {
      selected = clearService(
        selected,
        service.flight_date,
        flightInbound ? service.flight_sta : service.flight_std
      );
      selected.id = newId;
      selected.name = `${selected.name} ${servicesCount[selected.code]}`;
      setServicesByContract((prevState) => {
        let newState = [...prevState, selected];
        newState = newState.sort((a, b) => {
          if (a.priority < b.priority) {
            return -1;
          }
          if (a.priority > b.priority) {
            return 1;
          }
          return 0;
        });
        return newState;
      });

      setTimeout(() => {
        setSelectedService("");
        setServicesCount((prevState) => {
          const newState = { ...prevState };
          newState[selected.code] += 1;
          return newState;
        });
      }, 1000);
    }
  };

  const removeServiceHandler = (id, event) => {
    const serviceToRemove = servicesByContract.find(
      (s) => s.id.toString() === id.toString()
    );
    if (
      isInteger(id) &&
      ![
        "Cargo Loading",
        "Cargo Unloading",
        "Baggage Loading",
        "Baggage Unloading",
      ].includes(serviceToRemove.name)
    ) {
      serviceToRemove._destroy = true;
      setRemoveServices((prevState) => [...prevState, serviceToRemove]);
    }
    if (serviceToRemove) {
      alertify
        .confirm(
          "Triangle Services Aviation",
          `Would you like to delete ${serviceToRemove.name} service?`,
          function () {
            document.querySelector(
              ".alertify.ajs-movable.ajs-closable"
            ).style.display = "none";

            setServicesByContract((prevState) => {
              const newState = prevState.filter(
                (s) => s.id.toString() !== id.toString()
              );
              console.log(newState);
              return newState;
            });
            setServicesCount((prevState) => {
              const newState = { ...prevState };
              newState[serviceToRemove.code] -= 1;
              return newState;
            });
            alertify.success(locale("deleted"));
          },
          function () {
            document.querySelector(
              ".alertify.ajs-movable.ajs-closable"
            ).style.display = "none";
            alertify.error("Cancelled");
          }
        )
        .set("labels", { ok: locale("yes"), cancel: locale("No") });
    }
  };

  const formSubmitHandler = () => {
    const arr = servicesByContract.reduce((obj, prop) => {
      obj[prop.id] = validateService(prop);
      return obj;
    }, {});
    setErrors(arr);

    const arr2 = [];
    const keys = Object.keys(arr);
    for (let i = 0; i < keys.length; i++) {
      arr2.push(...Object.values(arr[keys[i]]));
    }

    if (arr2.includes(true)) {
      alertify.error(locale("error_messages.main1"));
    } else {
      setIsDisabled(true);
      extraStepFinished ? formSubmitProcessor() : setOpenModal(true);
    }
  };

  const formSubmitProcessor = () => {
    const data = {
      rfo_report: {
        id: newReport ? null : rampReport.id,
        date: service.flight_date,
        flight_type: flightType,
        airline: service.airline,
        flight_in: service.flight_in,
        flight_out: service.flight_out,
        aircraft_type: service.aircraft_type,
        tail_number: service.flight_number,
        service_id: service.id,
        admin_user_id: adminId,
        emp_num: numEmployees,
        aft_loader: crewMembers["aft_loader"]
          .map((el) => el["value"])
          .join(", "),
        loader: crewMembers["loader"].map((el) => el["value"]).join(", "),
        tug_drivers: crewMembers["tug_drivers"]
          .map((el) => el["value"])
          .join(", "),
        bulck1: crewMembers["bulck1"].map((el) => el["value"]).join(", "),
        bulck2: crewMembers["bulck2"].map((el) => el["value"]).join(", "),
        contract_services_attributes: [
          ...servicesByContract.map((s) => {
            if (newReport) {
              s.id = null;
              s.template = false;
              s.shared = flightInbound ? s.shared : false;
              delete s.group_name;
            }
            return s;
          }),
          ...removeServices,
        ],
      },
    };
    const response = submitData(data);
    if (response) {
      localStorage.removeItem(`rfo_report_${service.id}`);
      localStorage.removeItem(`crew_members_${service.id}`);
      updateService(service.id, flightType);
      setIsDisabled(false);
      closeBtnClick(false);
    }
  };

  const submitData = async (data) => {
    if (isOnline) {
      const alertMessage = newReport
        ? locale("success_messages.report_saved")
        : locale("success_messages.report_updated");
      const statusCode = newReport ? 201 : 204;
      const response = newReport
        ? axios.post("/admin/reports.json", data, {
            headers: {
              "Content-Type": "application/json",
            },
          })
        : axios.put(`/admin/reports/${rampReport.id}.json`, data, {
            headers: {
              "Content-Type": "application/json",
            },
          });

      response
        .then((response) => {
          if (response.status == statusCode) {
            alertify.success(alertMessage, 2);
          }
        })
        .catch((error) => {
          alertify.error(locale("error_messages.main"), 5);
        });
    } else {
      // is Offline
      let arr =
        localStorage.getItem("rforeports") === null
          ? []
          : JSON.parse(localStorage.getItem("rforeports"));
      arr.push(data);
      localStorage.setItem("rforeports", JSON.stringify(arr));
      alertify.success(locale("success_messages.report_saved"), 2);
    }
    return true;
  };

  const fieldChangeHandler = (id, field, value) => {
    const newState = servicesByContract.map((s) => {
      if (s.id === id) {
        s[field] = value;
      }
      return s;
    });
    setServicesByContract(newState);
  };

  const timeExpire = new Date(rampReport.created_at);
  timeExpire.setHours(timeExpire.getHours() + 1);

  return (
    <ThemeProvider theme={theme}>
      {!newReport && (
        <MyAlert
          message={`${locale("helper_text.ramp_report")} ${formatDateAndTime(
            timeExpire
          )}`}
          severity="warning"
          variant="standard"
        />
      )}
      {!showForm && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress color="secondary" />
        </Box>
      )}
      {showForm && (
        <Fragment>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <FormControl sx={{ m: 1, width: 180 }} size="small">
                <InputLabel id="add-service-label">
                  {locale("add_service")}
                </InputLabel>
                <Select
                  labelId="add-service-label"
                  id="add-service"
                  value={selectedService}
                  label={locale("add_service")}
                  onChange={addServiceHandler}
                >
                  <MenuItem value="">
                    <em>{locale("none")}</em>
                  </MenuItem>
                  {additionalServices.map((t) => (
                    <MenuItem value={t.id} key={t.id}>
                      {t.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <IconButton onClick={closeBtnHandler}>
                <Close />
              </IconButton>
            </Box>
          </Box>
          <Grid container sx={{ mb: "20px" }}>
            <Grid item sm={12} flexGrow={1}>
              <h2
                style={{
                  color: textColor,
                  textAlign: "center",
                  marginTop: "20px",
                }}
              >
                {locale("ramp_report")}
              </h2>
            </Grid>
            <Grid item xs={12} md={6}>
              <TableContainer component={Paper} sx={{ borderRadius: 0 }}>
                <Table
                  sx={{ marginBottom: "0px !important" }}
                  aria-label="simple table"
                >
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          background: statusColor,
                          minWidth: "180px",
                          color: "#FFFFFF",
                          width: "150px",
                        }}
                      >
                        {locale("date").toUpperCase()}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          background: "#FFFFFF",
                          color: textColor,
                          fontWeight: "normal",
                        }}
                        component="td"
                      >
                        {formatDateUS(service.flight_date)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          background: statusColor,
                          minWidth: "180px",
                          color: "#FFFFFF",
                        }}
                      >
                        {locale("airline").toUpperCase()}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          background: "#FFFFFF",
                          color: textColor,
                          fontWeight: "normal",
                        }}
                        component="td"
                      >
                        {service.airline}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          background: statusColor,
                          minWidth: "180px",
                          color: "#FFFFFF",
                        }}
                      >
                        {locale("aircraft_type").toUpperCase()}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          background: "#FFFFFF",
                          color: textColor,
                          fontWeight: "normal",
                        }}
                        component="td"
                      >
                        {service.aircraft_type}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          background: statusColor,
                          minWidth: "180px",
                          color: "#FFFFFF",
                        }}
                      >
                        {locale("tail_number").toUpperCase()}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          background: "#FFFFFF",
                          color: textColor,
                          fontWeight: "normal",
                        }}
                        component="td"
                      >
                        {service.flight_number}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={6}>
              <TableContainer component={Paper} sx={{ borderRadius: 0 }}>
                <Table
                  sx={{ marginBottom: "0px !important" }}
                  aria-label="simple table"
                >
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow
                        key={row.th1}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          align="center"
                          sx={{
                            background: statusColor,
                            minWidth: "180px",
                            color: "#FFFFFF",
                            fontWeight: "normal",
                            borderLeft: "1px solid #e4eaec !important",
                            width: "180px",
                          }}
                          component="th"
                          scope="row"
                        >
                          {row.th1}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: textColor, fontWeight: "normal" }}
                          component="td"
                        >
                          {row.td1}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          {servicesByContract.map((service) => {
            const validationErrors = errors[service.id?.toString()] || {
              unit1: false,
              unit2: false,
              unit3: false,
              unit4: false,
              unit5: false,
              signer_name: false,
              start_loc: false,
              dest: false,
              start_time: false,
              end_time: false,
            };

            if (service.name === "Safety Cones") {
              return (
                <Fragment key={service.id}>
                  <div className="cserv-div">
                    <ContractService
                      service={service}
                      setErrors={setErrors}
                      errors={validationErrors}
                      onDelete={removeServiceHandler}
                      onChange={fieldChangeHandler}
                      arrival={flightInbound}
                      locale={locale}
                    />
                  </div>
                  <br />
                  <OutlinedDiv label="Crew Members # Emp.">
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      justifyContent="left"
                      sx={{ paddingBottom: "10px" }}
                    >
                      <Grid item xs={5} md={2}>
                        <InputLabel htmlFor="loader">FWR LOADER</InputLabel>
                      </Grid>
                      <Grid item xs={7} md={2}>
                        <Creatable
                          placeholder={locale("select")}
                          isMulti
                          components={animatedComponents}
                          options={names}
                          onChange={handleChange.bind(this, "loader")}
                          value={crewMembers["loader"]}
                        />
                      </Grid>

                      <Grid item xs={5} md={2}>
                        <InputLabel htmlFor="aft_loader">AFT LOADER</InputLabel>
                      </Grid>
                      <Grid item xs={7} md={2}>
                        <Creatable
                          placeholder={locale("select")}
                          isMulti
                          components={animatedComponents}
                          options={names}
                          onChange={handleChange.bind(this, "aft_loader")}
                          value={crewMembers["aft_loader"]}
                        />
                      </Grid>

                      <Grid item xs={5} md={2}>
                        <InputLabel htmlFor="tug_drivers">
                          TUG DRIVERS
                        </InputLabel>
                      </Grid>
                      <Grid item xs={7} md={2}>
                        <Creatable
                          placeholder={locale("select")}
                          isMulti
                          components={animatedComponents}
                          options={names}
                          onChange={handleChange.bind(this, "tug_drivers")}
                          value={crewMembers["tug_drivers"]}
                        />
                      </Grid>

                      <Grid item xs={5} md={2}>
                        <InputLabel htmlFor="bulck1">BULCK</InputLabel>
                      </Grid>
                      <Grid item xs={7} md={2}>
                        <Creatable
                          placeholder={locale("select")}
                          isMulti
                          components={animatedComponents}
                          options={names}
                          onChange={handleChange.bind(this, "bulck1")}
                          value={crewMembers["bulck1"]}
                        />
                      </Grid>

                      <Grid item xs={5} md={2}>
                        <InputLabel htmlFor="bulck2">BULCK</InputLabel>
                      </Grid>
                      <Grid item xs={7} md={2}>
                        <Creatable
                          placeholder={locale("select")}
                          isMulti
                          components={animatedComponents}
                          options={names}
                          onChange={handleChange.bind(this, "bulck2")}
                          value={crewMembers["bulck2"]}
                        />
                      </Grid>

                      <Grid item xs={5} md={2}>
                        <InputLabel htmlFor="emp_num"># EMP.</InputLabel>
                      </Grid>
                      <Grid item xs={7} md={2}>
                        <Input
                          type="number"
                          name="emp_num"
                          value={numEmployees}
                          onChange={(e) => setNumEmployees(e.target.value)}
                          sx={{ width: "100%" }}
                        />
                      </Grid>
                    </Grid>
                  </OutlinedDiv>
                </Fragment>
              );
            }
            return (
              <div className="cserv-div" key={service.id}>
                <ContractService
                  service={service}
                  setErrors={setErrors}
                  errors={validationErrors}
                  onDelete={removeServiceHandler}
                  onChange={fieldChangeHandler}
                  arrival={flightInbound}
                  locale={locale}
                />
              </div>
            );
          })}

          {servicesByContract.length > 0 && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ mt: "40px", mb: "30px" }}
            >
              <ButtonGroup
                size="large"
                variant="outlined"
                aria-label="outlined button group"
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={deleteBtnHandler}
                >
                  {locale("delete_draft")}
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={saveBtnHandler}
                  sx={{ color: "white" }}
                >
                  {locale("save_draft")}
                </Button>
                <Button
                  variant="contained"
                  color="info"
                  onClick={formSubmitHandler}
                  disabled={isDisabled}
                >
                  {newReport ? locale("submit") : locale("update")}{" "}
                  {locale("report")}
                </Button>
              </ButtonGroup>
            </Grid>
          )}
          <ReportModal
            open={openModal}
            setOpen={setOpenModal}
            setIsDisabled={setIsDisabled}
            extraServices={additionalServices}
            addExtraServices={addServices}
            formSubmitProcessor={formSubmitProcessor}
          />
        </Fragment>
      )}
    </ThemeProvider>
  );
};

export default RFOReport;
